<template>
  <div>
    <section
      class="card pull-up mb-4"
      v-for="(item, index) in wallets"
      :key="'wallet-' + index"
    >
      <div class="card-content">
        <div class="card-body">
          <div class="col-12 pl-0">
            <div class="row justify-content-md-center align-items-md-center">
              <div class="col-md-9 col-12">
                <div class="media align-items-center">
                  <img
                    :src="item.wallet.currencyIcon"
                    class="cc mr-4 font-large-2 warning d-none d-md-block"
                    height="30"
                    alt=""
                  />
                  <div class="media-body">
                    <div class="mt-0 text-capitalize currency-name">
                      {{ item.wallet.currencyName }}
                      {{ item.wallet.currencyCode }}
                    </div>
                    <div>₺{{ item.balance }}</div>
                    <div class="text-muted mr-2 font-small-3 wallet-address">
                      {{ item.wallet.walletAddress }}<br />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12 text-right d-none d-md-block">
                <div v-if="item.wallet.qrImageCode" class="qrcode">
                  <div
                    :style="{
                      backgroundImage: `url(${item.wallet.qrImageUrl})`
                    }"
                    class="img-zoom"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      wallets: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cüzdan Yönetimi" },
      { title: "Cüzdanlar" }
    ]);
  },
  methods: {
    ...mapActions({
      getWallets: "wallets/GET_COMPANY_WALLETS"
    })
  },
  created() {
    this.getWallets().then(wallets => (this.wallets = wallets));
  }
};
</script>

<style lang="scss" scoped>
.currency-name {
  font-weight: bold;
  font-size: 20px;
}

.qrcode {
  height: 50px;
  position: relative;
  z-index: 1;

  .img-zoom {
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: contain;
    position: absolute;
    display: inline-block;
    right: 0;
  }

  &:hover {
    z-index: 9;

    .img-zoom {
      width: 200px;
      height: 200px;
      border: 1px solid #e8e8e8;
      border-radius: 10px;
      box-shadow: 0 0 40px 32px rgba(0, 0, 0, 0.09);
    }
  }
}
</style>
